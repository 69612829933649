@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Medium.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: 400;
}

* {
  font-family: 'Poppins';
  font-weight: 200;
  margin: 0;
  color: white;
}

body{
  background-color: #4B7BE5;
}

.wrapperHeader {
  overflow: hidden;
  position: sticky;
  top: 0;
}

.menu{
  /* margin-top: 1.30vw; */
  float: right;
  /* margin-right: 5%; */
  width: 60%;
  height: 7vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

ul {
  float: right;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-right: 5vw;
  margin-top: 1vw;
}

li {
  float: left;
  font-size: 1.30vw;
}

li a {
  display: block;
  padding: 0.41vw;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .menu {
    width: 70%;
  }
}

.contentWrapper {
  overflow: hidden;
}

.logo {
  float: left;
  width: 30%;
}

#transport {
  display: inline-block;
  width: 100%;
}

.header{
  float: left;
  margin-left: 6%;
  width: 40%;
}

.transportImg{
  float: right;
  width: 50%;
}

h1{
  font-size: 4.16vw;
  font-weight: 400;
  margin-top: 10%;
  white-space: nowrap;
  text-shadow: 15px 10px 8px rgba(0, 0, 0, 20%);
}

h2{
  font-size: 2.60vw;
  font-weight: 300;
  white-space: nowrap;
  text-shadow: 15px 10px 8px rgba(0, 0, 0, 20%);
}

.transportContent{
  margin-top: 10%;
  display: inline-block;
  align-items: center;
}

p{
  font-size: 1.82vw;
  margin-left: 0.52vw;
  text-align: left;
  text-shadow: 15px 10px 8px rgba(0, 0, 0, 20%);
  line-height: 5.20vw;
}

#merchanting, #development, #advertising {
  display: inline-block;
  width: 100%;
}

.merchantingIMG, .advertisingImg{
  float: left;
  width: 50%;
}

.merchantingContent{
  float: right;
  margin-right: 12%;
  margin-top: 6%;
}

.developmentImg{
  float: right;
  width: 50%;
}

.developmentContent{
  float: left;
  margin-left: 6%;
  margin-top: 6%;
}

.advertisingContent{
  margin-top: 6%;
  float: right;
  margin-right: 2%;
}


.contactImg{
  height: 80.05vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: top;
}

#contact{
  margin-top: 6vw;
  text-align: center;
}

#contact > h2{
  margin-bottom: 1.50vw;
}

#contact > p, .contactTextP {
  text-align: center;
  margin: 0;
  line-height: 2.60vw;
}

.contactTextP{
  margin-top: 2.60vw;
}

img{
  max-width:100%;
  height: auto;
}

.contactTextPsec{
  text-align: center;
  line-height: 2.60vw;
}

#map{
  margin-top: 2.60vw;
}